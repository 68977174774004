<template>
	<div class="organization">
		<div class="topbox">
			<div style="height: 100%;" v-for="(item,index) in toplist" :key="index">
				<div class="onetop nochoosetext" @click="chooseTop(index)" v-if="permissionList.includes(item.name) || isAdmin">
					<span :class="{'activespan': index === topIndex}">{{item.text}}</span>
					<div v-if="index === topIndex"></div>
				</div>
			</div>
		</div>
		
		<router-view></router-view>
	</div>
</template>

<script>
	
	export default {
		data() {
			return {
				// toplist: ['','角色管理','账号管理','权限管理'],
				toplist: [{
					text: '部门管理',
					name: 'bumengManage'
				},
				{
					text: '角色管理',
					name: 'jueseManage'
				},
				// {
				// 	text: '账号管理',
				// 	name: 'zhanghaoManage'
				// },
				// {
				// 	text: '权限管理',
				// 	name: 'roleManage'
				// },
				],
				topIndex: 0,
			}
		},
		name: 'Organization',
		computed: {
			permissionList() {
				return this.$store.state.permissionList 
			},
			isAdmin() {
				return (this.$store.state.isadmin && localStorage.getItem('isStaff') == '0')
			},
		},
		created() {
			this.getRoutePath()
		},
		methods: {
			// 获取当前路由地址
			getRoutePath() {
				let route = this.$route.path
				if (route == '/organization/bumengmanage') {
					this.topIndex = 0
				} else if (route == '/organization/juesemanage' || route == '/organization/addjuese') {
					this.topIndex = 1
				} else if (route == '/organization/zhanghaomanage') {
					this.topIndex = 2
				}  else if (route == '/organization/rolemanage') {
					this.topIndex = 3
				} 
			},
			// 点击顶部导航
			chooseTop(index) {
				this.topIndex = index
				if (index === 0) {
					this.$router.push({
						path: "/organization/bumengmanage"
					})
				} else if (index === 1) {
					this.$router.push({
						path: "/organization/juesemanage"
					})
				} else if (index === 2) {
					this.$router.push({
						path: "/organization/zhanghaomanage"
					})
				} else if (index === 3) {
					this.$router.push({
						path: "/organization/rolemanage"
					})
				} 
			}

		}
	}
</script>

<style scoped lang="scss">
	.onetop {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin: 0 3.75em 0 0;
		cursor: pointer;
		span {
			font-size: 1.125em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #444444;
			&.activespan {
				font-size: 1.125em;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2D74F4;
			}
		}
		div {
			width: 1.875em;
			height: 0.1875em;
			position: absolute;
			bottom: 0;
			background-color: #3286FF;
		}
	}
	.topbox {
		padding: 0 1.0625em;
		display: flex;
		align-items: center;
		width: calc(100% - 2.5em);
		height: 3.25em;
		background-color: #ffffff;
		margin: 0.9375em auto 0;
	}
@media screen and (max-width: 1920px) {
	.organization {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.organization {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.organization {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.organization {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.organization {
		font-size: 12px;
	}
}
</style>